<template>
	<div class="param">
		<div class="block">
			<div class="label-0">
				设备名称
			</div>
			<div class="value-0">
				<span>{{devName || '加载中'}}</span>
			</div>
		</div>
		<div class="block">
			<div class="label-0">
				设备编号
			</div>
			<div class="value-0">
				<span>{{devCode}}</span>
			</div>
		</div>
		<div class="block">
			<div class="label-0">
				通讯地址
			</div>
			<div class="value-0">
				<span>{{form.uwbId || '读取失败'}}</span>
			</div>
		</div>
		<div class="block">
			<div class="label-0" style="line-height: 1.5">
				锥桶名称
			</div>
			<div class="value-0">
                <van-radio-group v-model="form.bucketType" direction="horizontal">
                    <van-radio :name="item.value" checked-color="#9097FF" v-for="(item,index) in opt.type" :key="index">{{item.name}}</van-radio>
                </van-radio-group>
			</div>
		</div>
		<div class="block">
			<div class="label-0">
				OLED节目
			</div>
			<div class="value-0" @click="oledShowPicker = true">
                {{current.oledProgramText || '加载中...'}}
			</div>
		</div>
		<div class="block">
			<div class="label-0">
				LED节目
			</div>
			<div class="value-0" @click="ledShowPicker = true">
                {{current.ledProgramText || '加载中...'}}
			</div>
		</div>
		<div class="block">
			<div class="label-0">
				语音节目
			</div>
			<div class="value-0" @click="speakerShowPicker = true">
                {{current.speakerProgramText || '加载中...'}}
			</div>
		</div>
		<div class="block">
			<div class="label-0" style="line-height: 2">
				报警模式
			</div>
			<div class="value-0">
                <van-switch v-model="form.alarmType" active-color="#9097FF" size="25" />
			</div>
		</div>
		<div class="block">
			<div class="label-0" style="line-height: 1.5">
				雷达模式
			</div>
			<div class="value-0">
                <van-radio-group v-model="form.radarMode" direction="horizontal">
                    <van-radio :name="item.value" checked-color="#9097FF" v-for="(item,index) in opt.radar" :key="index">{{item.name}}</van-radio>
                </van-radio-group>
			</div>
		</div>
		<div class="block">
			<div class="label-0 line-height-2">
				喇叭音量
			</div>
			<div class="value-0">
                <div style="width:80%">
                    <van-slider v-model="form.speakerVol" :min="0" :max="15" bar-height="10px" active-color="#9097FF" />
                </div>
                <div style="width:10%;margin-left:20px">{{form.speakerVol}}</div>
			</div>
		</div>
		<div class="block">
			<div class="label-0" style="line-height: 2">
				永久保存
			</div>
			<div class="value-0">
                <van-switch v-model="saveMode" active-color="#9097FF" size="25" />
			</div>
		</div>
		<div class="btn">
			<button class="submit bg-0" type="default" @click="loadParam">读取参数</button>
			<button class="submit bg-1" type="primary" @click="submitParam">写入参数</button>
			<button class="submit bg-2" type="warn" @click="resetDev">重启设备</button>
		</div>
        <!-- OLED弹出框 -->
        <van-popup v-model="oledShowPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="opt.oled"
                value-key="dictLabel"
				:default-index="current.oleddefaultIndex"
                @cancel="oledShowPicker = false"
                @confirm="confirmOled"
            />
        </van-popup>
        <!-- LED弹出框 -->
        <van-popup v-model="ledShowPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="opt.led"
                value-key="dictLabel"
				:default-index="current.ledfaultIndex"
                @cancel="ledShowPicker = false"
                @confirm="confirmLed"
            />
        </van-popup>
        <!-- 语言节目弹出框 -->
        <van-popup v-model="speakerShowPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="opt.speaker"
                value-key="dictLabel"
				:default-index="current.speakerdefaultIndex"
                @cancel="speakerShowPicker = false"
                @confirm="confirmSpeaker"
            />
        </van-popup>
	</div>
</template>

<script>
const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'

	export default {
	    data() {
			return{
				devName:null,
				devCode:null,
				index:{
					oled:0,
					led:0,
					speaker:0,
				},
				current:{
                    oledProgramText:'',
                    ledProgramText:'',
                    speakerProgramText:'',
					oleddefaultIndex:0,
					ledfaultIndex:0,
					speakerdefaultIndex:0,
				},
				opt:{
					type:[
						{name:'报警桶',value:'0'},
						{name:'探测桶',value:'1'},
					],
					led:[{dictLabel:''}],  // 修复初始化获取不到dictLabel导致的报错
					oled:[{dictLabel:''}],
					speaker:[{dictLabel:''}],
					radar:[
						{name:'正向',value:'1'},
						{name:'斜向',value:'2'},
					]
				},
				form:{
					uwbId:'',
					bucketType:'',
					oledProgram:'',
					ledProgram:'',
					speakerProgram:'',
					alarmType:true,
					speakerVol:0,
				},
                oledShowPicker:false,
                ledShowPicker:false,
                speakerShowPicker:false,
				saveMode:false, //0是不保存 1是保存
				client:null,
			}
		},
		mounted() {
			this.devCode = this.$route.query.devCode;
			this.devName = this.$route.query.devName;
			this.getOpt();
		},
		beforeDestroy(){
			this.client ? this.client.end() : void 0;
		},
		methods:{
			confirmOled(e){
                this.current.oledProgramText = e.dictLabel;
                this.form.oledProgram = e.dictValue;
                this.oledShowPicker = false;
            },
            confirmLed(e){
                this.current.ledProgramText = e.dictLabel;
                this.form.ledProgram = e.dictValue;
                this.ledShowPicker = false;
            },
            confirmSpeaker(e){
                this.current.speakerProgramText = e.dictLabel;
                this.form.speakerProgram = e.dictValue;
                this.speakerShowPicker = false;
            },
			// 读取参数
			loadParam(){
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration:0
				});
				this.$api.BUCKET.readParam({
					devCode:this.devCode
				}).then(()=>{
					setTimeout(()=>{
						this.$toast.clear();
					},1000)
				})
			},
			// 获取节目单
			getOpt(){
				this.$api.BUCKET.programInfo('oled_program').then( d => {
					Array.isArray(d) ? this.opt.oled = d : void 0;
				})
				this.$api.BUCKET.programInfo('led_program').then( d => {
					Array.isArray(d) ? this.opt.led = d : void 0;
				})
				this.$api.BUCKET.programInfo('speaker_program').then( d => {
					Array.isArray(d) ? this.opt.speaker = d : void 0;
				})
				setTimeout(()=>{
					this.initMqtt();
				},1000)
			},
			// 保存参数
			submitParam(){
				if(!this.form.uwbId){
					return this.$toast('通讯地址为空,请返回重试')
				}
				let data = { ...this.form};
				delete data.id;
				data.devCode = this.devCode;
				data.alarmType = this.form.alarmType ? '0' : '1';
				data.speakerVol = data.speakerVol.toString();
				data.saveMode = this.saveMode ? 1 : 0;
				if(!data.devCode){
					this.$toast('devCode为空,请返回重试');
				}
				this.$api.BUCKET.writeParam(data).then( () => {
					this.$toast('写入参数成功');
				})
			},
			// 重启设备
			resetDev(){
				this.$api.BUCKET.rebootStm({
					devCode:this.devCode
				}).then( d => {
					this.$toast('操作成功');
					setTimeout(()=>{
						this.$router.push({
							path: "/bucket/list"
						});
					},500)
				})
			},
			// 订阅
			initMqtt(){
				this.client  = mqtt.connect('ws://ahdcloud.com:20502/mqtt',MQTT_OPTIONS);
				this.client.on('connect', (e) => {
					console.log(e, "MQTT连接成功！！！");
					const paramInfo = `/sudp/+/bucket/+/${this.devCode}/config/get`;
					this.client.subscribe(paramInfo, {}, (error) => {  // qos 为通道
						if (!error) {
							console.log('告警消息订阅成功',paramInfo)
						} else {
							console.log('告警消息订阅失败')
						}
						this.loadParam();
					})
				})
				// 接收消息处理
				this.client.on('message', (topic, message) => {
					const topicArr = topic.split('/');
					const channel = topicArr[topicArr.length-2];
					if(channel === 'config'){
						const data = JSON.parse(message.toString());
						console.log(topicArr,data)
						this.form = { ...data };
						this.form.speakerVol = Number(data.speakerVol);
						// 节目单回显
						this.current.oledProgramText  = this.opt.oled ? this.opt.oled.find( v => v.dictValue == data.oledProgram)?.dictLabel : '加载失败',
						this.current.oleddefaultIndex  = this.opt.oled ? this.opt.oled.findIndex( v => v.dictValue == data.oledProgram) : 0,
						this.current.ledProgramText  = this.opt.led ? this.opt.led.find( v => v.dictValue == data.ledProgram)?.dictLabel : '加载失败',
						this.current.ledfaultIndex  = this.opt.led ? this.opt.led.findIndex( v => v.dictValue == data.ledProgram) : 0,
						this.current.speakerProgramText  = this.opt.speaker ? this.opt.speaker.find( v => v.dictValue == data.speakerProgram)?.dictLabel : '加载失败',
						this.current.speakerdefaultIndex  = this.opt.speaker ? this.opt.speaker.findIndex( v => v.dictValue == data.speakerProgram) : 0,
						this.form.alarmType  = data.alarmType === '0' ? true : false   // 激活报警 0   关闭告警 1
					}
				})
				// 断开发起重连
				this.client.on('reconnect', (error) => {
					console.log('正在重连:', error)
				})
				// 链接异常处理
				this.client.on('error', (error) => {
					console.log('连接失败:', error)
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
.param{
	overflow-y: auto;
    height: 100%;
	.block{
		padding: 10px;
		border-bottom:1px solid #2c3346;
		font-size: .95rem;
		color: #fff;
		display: flex;
        text-align: left;
        align-items: center;
        line-height: 2;
		.label-0{
			width: 22%;
			font-weight: bold;
			color: #fff;
		}
		.value-0{
			width: 78%;
			color: #fff;
            display: flex;
            align-items: center;
			.input{
				font-size: .95rem;
				color:#cecece;
			}
			.label{
				margin-right: 8px;
			}
		}
	}
	.btn{
		padding: 10px;
        display: flex;
        flex-direction: column;
		.submit{
			margin: 15px 25%;
			border-radius: 15px;
			line-height: 3;
			font-size: .95rem;
            font-weight: bolder;
            border: none;
		}
		.bg-0{
			background-color: #9097FF;
			color: #fff;
			&:active{
				opacity: 0.8;
			}
		}
		.bg-1{
			background-color: #62CEFF;
			color: #fff;
			&:active{
				opacity: 0.8;
			}
		}
		.bg-2{
			background-color: #E70012;
			color: #fff;
			&:active{
				opacity: 0.8;
			}
		}
	}
	.line-height-2{
		line-height: 2.5;
	}
}
</style>
